
export default class Client {
  constructor () {
    this.setInitialValue()
  }

  setInitialValue () {
    this.user = {
      id: null,
      image: '',
      imageUrl: null,
      name: '',
      email: '',
      phone: '',
      birthdate: '',
      gender: '',
      password: '',
      isActive: 0
    }
  }

  fillData (data) {
    if (data) {
      this.user.id = data.id ? data.id : null
      this.user.imageUrl = data.image ? data.image : null
      this.user.name = data.name ? data.name : ''
      this.user.email = data.email ? data.email : ''
      this.user.phone = data.phone ? data.phone : ''
      this.user.birthdate = data.birthdate ? data.birthdate : ''
      this.user.gender = data.gender ? data.gender : ''
      this.user.password = data.password ? data.password : ''
      this.user.isActive = data.isActive ? data.isActive : 0
    } else {
      this.setInitialValue()
    }
  }
}
