<template>
  <div>
    <b-card>
      <ValidationObserver ref="client" v-slot="{ handleSubmit }">
        <b-form class="form-top p-4" @submit.prevent="handleSubmit(formSubmit)">
        <b-row>
          <b-col md="12">
            <!-- <input-file :defaultUrl="client.user.imageUrl" :name="$t('global.profileImage')" :label="$t('global.profileImage')" :validate="currentPage == itemPages.create ? '' : ''" :disabled="disableStatus" /> -->
            <cropper-images
                :label="$t('global.coverImage')"
                nameOfImage="image.jpg"
                :multi="false"
                :imageUrl="client.user.imageUrl"
                :ratio="1"
                :disabled="disableStatus"
            />
          </b-col>
          <b-col md="12">
            <input-form v-model="client.user.name" :name="$t('table.name')" :label="$t('table.name')" :disabled="disableStatus" />
          </b-col>

          <b-col md="6">
            <input-form v-model="client.user.email" :name="$t('global.email')" :label="$t('global.email')" :disabled="disableStatus" />
          </b-col>
          <b-col md="6">
            <input-form v-model="client.user.phone" :name="$t('global.phone')" :label="$t('global.phone')" :disabled="disableStatus" />
          </b-col>

          <b-col md="6">
            <input-form v-model="client.user.birthdate" :name="$t('global.birthDate')" :label="$t('global.birthDate')" :disabled="disableStatus" />
          </b-col>
          <b-col md="6">
            <input-form v-model="client.user.gender" :name="$t('global.gender')" :label="$t('global.gender')" :disabled="disableStatus" />
          </b-col>
        </b-row>
      </b-form>
      </ValidationObserver>
    </b-card>

    <b-card v-if="currentPage == itemPages.view">
      <contact-us :userId="id" :titleStatus="true" />
      <client-transactions :userId="id" :titleStatus="true" />
    </b-card>
  </div>
</template>
<script>
import { core } from '../../../../config/pluginInit'
import clientsServices from '../services/clients'
import Client from '../models/Client'
import ContactUs from '../../contactUs/views/ContactUs.vue'
import ClientTransactions from '../../../transactions/clientTransactions/views/ClientTransactions.vue'
import moduleItemMixin from '../../../../Mixins/moduleItemMixin'

export default {
  mixins: [moduleItemMixin],
  components: { ContactUs, ClientTransactions },
  data () {
    return {
      client: new Client()
    }
  },
  mounted () {
    core.index()
  },
  created () {
    if (this.id) {
      clientsServices.findOne(this.id).then(response => {
        this.client.fillData(response.data.data)
        this.loading = false
      })
    } else {
      this.loading = false
    }
  }
}
</script>
